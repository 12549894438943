import React from "react";
import { Icon } from "@iconify/react";
import "./Footer.scss";
function Footer() {
  return (
    <section className="Footer">
      <div className="Footer-main">
        <div className="left">
          <a href="#">
            <img className="footer-logo" src="/Images/logo.png" alt="..." />
          </a>
          <p>
            Metanexus is an ecosystem connecting the Metaverse, including
            virtual reality, augmented reality, and the physical world. Offering
            real-world utility and endless immersive experiences to
            participants.
          </p>
          <span>Copyright © 2022 METAHUNTER. All rights reserved.</span>
        </div>
        <div className="center">
          <h6>Info</h6>
          <ul>
            <li>Privacy Policy</li>
            <li>Legal Notice</li>
            <li>Term of use</li>
          </ul>
        </div>
        <div className="right">
          <h5>Join the community</h5>
          <div className="icons">
            <a href="#">
              <Icon
                icon="ic:baseline-discord"
                color="white"
                width="40"
                height="40"
              />
            </a>
            <a href="#">
              <Icon icon="mdi:twitter" color="white" width="40" height="40" />
            </a>
            <a href="#">
              <Icon
                icon="bxl:instagram-alt"
                color="white"
                width="40"
                height="40"
              />
            </a>
            <a href="#">
              <Icon
                icon="ph:youtube-logo-fill"
                color="white"
                width="40"
                height="40"
              />
            </a>
            <a href="#">
              <Icon
                icon="ic:baseline-telegram"
                color="white"
                width="40"
                height="40"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
