import React, { useState } from "react";
import "./login.css";
import myImage from "../../Images/logomain.png";
import Navbar from "../Navbar/Navbar";
import { Icon } from "@iconify/react";
const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <section className="login">
        <Navbar />
        <div className="main-view">
          <div className="main-view-one">
            <img src={myImage} alt="My Image" className="img-fluid" />
          </div>

          {/* userName Password  Email */}
          <div className="main-view-two">
            <div className="inputDiv">
              <a href="#">
                <Icon icon="mdi:account" color="white" width="25" height="25" />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Username Email"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <div className="inputDiv">
              <a href="#">
                <Icon
                  icon="mdi:password"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Password"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <p className="textOne">Forgot Username or Password?</p>

            <button className="button">
              <p className="buttonText">Login</p>
            </button>
          </div>

          {/* Or Google Facebook section */}

          <div className="main-view-three">
            <p className="textOne">OR</p>

            <button className="buttonOne">
              <a href="#">
                <Icon
                  icon="flat-color-icons:google"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <p className="textGoogle">Google</p>
            </button>
            <button className="buttonTwo">
              <a href="#">
                <Icon
                  icon="logos:facebook"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <p className="textFacebook">Facebook</p>
            </button>
            <p className="textTwo">
              Don't Have Account?{" "}
              <a className="textTwoA" href="/signup">
                CREATE ACCOUNT
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
