import React, { useState, useEffect } from "react";
import "./Setting.css";
import myImage from "../../Images/logomain.png";
import profileImage from "../../Images/profile.png";
import vipImage from "../../Images/vip.png";
import profile from "../../Images/immersive.png";
import Navbar from "../Navbar/Navbar";
import Drawer from "../Drawer/Drawer";
import { Icon } from "@iconify/react";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";

const Setting = () => {
  const [Email, setEmail] = useState("");
  const [showOptionsMobile, setShowOptionsMobile] = useState(true);

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate("/dashboard");
  };

  const showMenu = () => {
    setShowOptionsMobile(true);
  };
  const hideMenu = () => {
    setShowOptionsMobile(false);
  };
  const windowWidth = window.innerWidth;
  useEffect(() => {
    if (windowWidth > 650) {
      setShowOptionsMobile(true);
      console.log("greater than 650");
    } else {
      setShowOptionsMobile(false);
      console.log("smaller than 650");
    }
  }, [windowWidth]);

  const handleChange = () => {
    setEmail((email) => Email);
  };

  return (
    <>
      <section className="Setting">
        <div
          className="Setting-view-Setting"
          style={{
            transform: `translateX(${showOptionsMobile ? "0" : "-1000px"})`,
          }}
        >
          <Drawer />
          <button
            className="Setting-view-two-one-button-Back"
            onClick={hideMenu}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color="white"
              width="25"
              height="25"
            />
            {/* <p className="buttonText">Back</p> */}
          </button>
        </div>

        {/* this is mainView */}
        <div className="Setting-view-main">
          {/* header section view */}
          <div className="Setting-view-main-first">
            <div
              className="drawerIcons d-flex justify-content-center"
              onClick={showMenu}
            >
              <Icon
                icon="mingcute:menu-fill"
                color="white"
                width="30"
                height="30"
              />
            </div>
            <div className="rightDiv d-flex justify-content-center">
              <button className="nav-btn-one m-0">Connect Wallet</button>
              <div className="nav-div">
                <div className="nav-div-div">
                  <img
                    src={profileImage}
                    alt="Profile Image"
                    height="35"
                    width="35"
                  />
                </div>
                <a
                  class="nav-div-link-tag"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SEEMI75
                </a>
                <Icon
                  icon="icon-park-outline:down"
                  color="white"
                  width="25"
                  height="25"
                />
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Name
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Profile Picture
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Check Status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* main section view */}
          <div className="Setting-view-main-second">
            <div className="heading">
              <p className="text">Account Settings</p>
            </div>

            {/* Second coming soon div */}
            <div className="comingsoon">
              {/* Left section start */}
              <div className="left">
                <div className="firstDiv">
                  <div className="first">
                    {/* <div className="status">
                      {" "}
                      <p className="text">80%</p>
                    </div> */}
                    <div className="profileInfo">
                      <p className="textOne">Profile Informations</p>
                      <p className="textTwo">Complete your profile</p>
                      <p className="textTwo">to unlock all features</p>
                    </div>
                  </div>
                  <button className="secondFbutton">
                    <p className="text">Complete Profile</p>
                  </button>
                </div>

                <div className="secondDiv">
                  <p className="text">Appearances</p>
                  <Icon
                    icon="mingcute:right-fill"
                    color="white"
                    width="25"
                    height="25"
                  />
                </div>

                <div className="thirdDiv">
                  <p className="text">Account Settings</p>
                  <Icon
                    icon="mingcute:right-fill"
                    color="white"
                    width="25"
                    height="25"
                  />
                </div>
              </div>

              {/* Right section start */}
              <div className="right">
                <p className="headings">First Name</p>

                <div className="InputDivMain">
                  <div className="inputDiv">
                    <input
                      className="inputSection"
                      type="text"
                      placeholder="Loream"
                      value={Email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <p className="headings">Last Name</p>
                <div className="InputDivMain">
                  <div className="inputDiv">
                    {/* <a href="#">
                          <Icon
                        icon="mdi:account"
                        color="white"
                        width="25"
                        height="25"
                         />
                       </a> */}
                    <input
                      className="inputSection"
                      type="text"
                      placeholder="ipsum"
                      value={Email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <p className="headings">Email Adress</p>
                <div className="InputDivMain">
                  <div className="inputDiv">
                    {/* <a href="#">
                          <Icon
                        icon="mdi:account"
                        color="white"
                        width="25"
                        height="25"
                         />
                       </a> */}
                    <input
                      className="inputSection"
                      type="text"
                      placeholder="loremipsum@gmail.com"
                      value={Email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Twitter Instagram adn button section */}
                {/* Twitter  div  */}
                <div className="twitterDiv">
                  <div className="firstDiv">
                    <a href="#">
                      <Icon
                        icon="uiw:twitter"
                        color="white"
                        width="25"
                        height="25"
                      />
                    </a>
                    <p className="text">Twitter</p>
                  </div>
                  <button className="connect">
                    <p className="text">Connect</p>
                  </button>
                </div>
                {/* Instagram  div  */}
                <div className="twitterDiv">
                  <div className="firstDiv">
                    <a href="#">
                      <Icon
                        icon="ph:camera-fill"
                        color="white"
                        width="25"
                        height="25"
                      />
                    </a>
                    <p className="text">Instagram</p>
                  </div>
                  <button className="connect">
                    <p className="text">Connect</p>
                  </button>
                </div>

                <div className="twoButtonDiv">
                  <button className="changesDiscard">
                    <p className="text">Discard Changes</p>
                  </button>
                  <button className="changesSave">
                    <p className="text">save Changes</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Setting;
