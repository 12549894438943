import React, { useState, useEffect } from "react";
import "./Referal.css";
import myImage from "../../Images/logomain.png";
import profileImage from "../../Images/profile.png";
import vipImage from "../../Images/vip.png";
import profile from "../../Images/immersive.png";
import Navbar from "../Navbar/Navbar";
import Drawer from "../Drawer/Drawer";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
const Referal = () => {
  const [showOptionsMobile, setShowOptionsMobile] = useState(true);

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate("/dashboard");
  };

  const showMenu = () => {
    setShowOptionsMobile(true);
  };
  const hideMenu = () => {
    setShowOptionsMobile(false);
  };
  const windowWidth = window.innerWidth;
  useEffect(() => {
    if (windowWidth > 650) {
      setShowOptionsMobile(true);
      console.log("greater than 650");
    } else {
      setShowOptionsMobile(false);
      console.log("smaller than 650");
    }
  }, [windowWidth]);

  return (
    <>
      <section className="Referal">
        <div
          className="Referal-view-Referal"
          style={{
            transform: `translateX(${showOptionsMobile ? "0" : "-1000px"})`,
          }}
        >
          <Drawer />

          <button
            className="Referal-view-two-one-button-Back"
            onClick={hideMenu}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color="white"
              width="25"
              height="25"
            />
            {/* <p className="buttonText">Back</p> */}
          </button>
        </div>

        {/* this is mainView */}
        <div className="Referal-view-main">
          {/* header section view */}
          <div className="Referal-view-main-first">
            <div
              className="drawerIcons d-flex justify-content-center"
              onClick={showMenu}
            >
              <Icon
                icon="mingcute:menu-fill"
                color="white"
                width="30"
                height="30"
              />
            </div>
            <div className="rightDiv d-flex justify-content-center">
              <button className="nav-btn-one m-0">Connect Wallet</button>
              <div className="nav-div">
                <div className="nav-div-div">
                  <img
                    src={profileImage}
                    alt="Profile Image"
                    height="35"
                    width="35"
                  />
                </div>
                <a
                  class="nav-div-link-tag"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SEEMI75
                </a>
                <Icon
                  icon="icon-park-outline:down"
                  color="white"
                  width="25"
                  height="25"
                />
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Name
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Profile Picture
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Check Status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* main section view */}
          <div className="Referal-view-main-second">
            <div className="heading">
              <p className="text">Refer friends and earn credit</p>
            </div>

            <div className="comingsoon">
              <p className="text">Share my referral link with friends</p>

              <div className="linkDiv">
                <p className="textLink">www.metanexus/referral/hfgsn</p>
                <Icon
                  icon="ph:copy-bold"
                  color="white"
                  width="22"
                  height="22"
                />
              </div>
              {/* button section starts from here */}
              <div className="buttonDiv">
                <button className="button">
                  <Icon
                    icon="ph:link-bold"
                    color="#343434"
                    width="22"
                    height="22"
                  />
                  <p className="text">Link</p>
                </button>

                <button className="buttonTwo">
                  <Icon
                    icon="logos:facebook"
                    color="white"
                    width="22"
                    height="22"
                  />
                  <p className="textTwo">Facebook</p>
                </button>
                <button className="buttonThree">
                  <Icon
                    icon="logos:whatsapp-icon"
                    color="white"
                    width="22"
                    height="22"
                  />
                  <p className="textTwo">Whatsapp</p>
                </button>
              </div>

              {/* Four block sction starts from here */}
              <div className="block">
                <div className="blockDiv">
                  <p className="textOne">54</p>
                  <p className="textTwo">Referal SignUps</p>
                </div>
                <div className="blockDiv">
                  <p className="textOne">41</p>
                  <p className="textTwo">Successfull Referal</p>
                </div>
                <div className="blockDiv">
                  <p className="textOne">12</p>
                  <p className="textTwo">Exchange Currency</p>
                </div>
                <div className="blockDiv">
                  <p className="textOne">500</p>
                  <p className="textTwo">Commision Made</p>
                </div>
              </div>

              {/* withdraw button starts  */}
              <button className="WithdrawButton">
                <p className="text">Withdraw Now</p>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Referal;
