import React, { useState, useEffect } from "react";
import "./dashboard.css";
import myImage from "../../Images/logomain.png";
import profileImage from "../../Images/profile.png";
import vipImage from "../../Images/vip.png";
import profile from "../../Images/immersive.png";
import Navbar from "../Navbar/Navbar";
import Drawer from "../Drawer/Drawer";
import { Icon } from "@iconify/react";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const options = {
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    colors: ["#D501FA", "#8C17C7", "#4A2A99"], // Specify colors for each series
    chart: {
      toolbar: {
        show: false, // Hide the toolbar (including zoom icons and other controls)
      },
    },
    zoom: {
      enabled: false, // Disable zoom functionality
    },
  };
  const series = [
    {
      name: "s1",
      data: [30, 40],
    },
  ];
  const [showOptionsMobile, setShowOptionsMobile] = useState(true);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [showOptions, setShowOptions] = useState(true);

  const handleToggleOptions = () => {
    console.log("caleedddd ---", showOptions);
    setShowOptions(!showOptions);
  };

  const showMenu = () => {
    setShowOptionsMobile(true);
  };
  const hideMenu = () => {
    setShowOptionsMobile(false);
  };
  const windowWidth = window.innerWidth;
  useEffect(() => {
    if (windowWidth > 650) {
      setShowOptionsMobile(true);
      console.log("greater than 650");
    } else {
      setShowOptionsMobile(false);
      console.log("smaller than 650");
    }
  }, [windowWidth]);

  return (
    <>
      <section className="dashboard">
        {/* this is drawer */}
        <div
          className="dashboard-view-dashboard"
          style={{
            transform: `translateX(${showOptionsMobile ? "0" : "-1000px"})`,
          }}
        >
          <Drawer />

          <button
            className="dashboard-view-two-one-button-Back"
            onClick={hideMenu}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color="white"
              width="25"
              height="25"
            />
            {/* <p className="buttonText">Back</p> */}
          </button>
        </div>

        {/* this is mainView */}
        <div className="dashboard-view-main">
          {/* header section view */}
          <div className="dashboard-view-main-first">
            <div
              className="drawerIcons d-flex justify-content-center"
              onClick={showMenu}
            >
              <Icon
                icon="mingcute:menu-fill"
                color="white"
                width="30"
                height="30"
              />
            </div>
            <div className="rightDiv d-flex justify-content-center">
              <button className="nav-btn-one m-0">Connect Wallet</button>
              <div className="nav-div">
                <div className="nav-div-div">
                  <img
                    src={profileImage}
                    alt="Profile Image"
                    height="35"
                    width="35"
                  />
                </div>
                <a
                  class="nav-div-link-tag"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SEEMI75
                </a>
                <Icon
                  icon="icon-park-outline:down"
                  color="white"
                  width="25"
                  height="25"
                />
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Name
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Profile Picture
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Check Status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* main section view */}

          <div className="dashboard-view-main-second">
            {/*section main starts */}
            <div className="dashboard-view-two-two-first">
              {/* Acount Profile div */}
              <div className="dashboard-view-two-two-first-first">
                <img
                  src={profileImage}
                  alt="My Image"
                  className="img-fluid profile-pic"
                />

                <div className="account">
                  <p className="textHeading">ACCOUNT</p>
                  <div className="content">
                    <p className="textName">Helena Jeff</p>
                    <p className="textEmail">helenaj233@metanexus.com</p>
                    <p className="textId">ID: helena.jeff233</p>
                  </div>
                </div>

                <div className="vipLogo">
                  <p className="textHeading">TIER</p>

                  <img src={vipImage} alt="My Image" className="logoImage" />
                </div>
              </div>
              <div className="dashboard-view-two-two-first-second">
                <p className="textHeading">Wallet Overview</p>
                <p className="textBalance">Your Balance</p>
                <p className="textDigit">ETH 233.12</p>
                <div className="tokenDiv">
                  {" "}
                  <p className="textBalance">MTX Token</p>
                  <p className="textBalance">MUSD Token</p>
                </div>
                <div className="tokenDivTwo">
                  {" "}
                  <p className="textBalance">127.23</p>
                  <p className="textBalance">347.51</p>
                </div>
              </div>
            </div>
            <div className="dashboard-view-two-two-second">
              <div className="dashboard-view-two-two-second-first">
                <p className="textOne">Staking reward overview</p>
                <div
                  style={{
                    height: "98%",
                    width: "90%",
                    marginTop: 10,
                  }}
                >
                  <Chart
                    options={options}
                    series={series}
                    width="100%"
                    height="100%"
                    type="area"
                  />
                </div>
                <p className="textTwo">reward: ETH 142</p>
              </div>

              <div className="dashboard-view-two-two-second-second">
                <p className="textFirst">Referral program overview</p>
                <p className="textSecond">
                  Join Our Referral program and receive Referral In Cash
                </p>
                <div className="firstDiv">
                  <p className="textFirst">www.metanexus/referral/hfgsn</p>
                  <Icon
                    icon="ph:copy-bold"
                    color="white"
                    width="25"
                    height="25"
                  />
                </div>

                {/* second div */}
                <div className="secondDiv">
                  <div className="leftDiv">
                    <p className="textFirst">$5123</p>
                    <p className="textSecond">Available to Withdraw</p>
                  </div>
                  <div className="rightDiv">
                    <button className="nav-btn-one">WITHDRAW</button>
                  </div>
                </div>
                {/* third Div */}
                <div className="thirdDiv">
                  <div className="leftDiv">
                    <p className="textFirst">$54</p>
                    <p className="textSecond">Referrals</p>
                  </div>
                  <div className="rightDiv">
                    <p className="textFirst">$845</p>
                    <p className="textSecond">Earned</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*section two starts */}
      </section>
    </>
  );
};

export default Dashboard;
