import "./App.css";
import Main1 from "./Components/Main1/Main1";
import Immersive from "./Components/Immersive/Immersive";
import Participate from "./Components/Participate/Participate";
import Customizable from "./Components/Customizable/Customizable";
import Social from "./Components/Social/Social";
import Token from "./Components/Token/Token";
import Trade from "./Components/Trade/Trade";
import Multiplayer from "./Components/Multiplayer/Multiplayer";
import Partners from "./Components/Partners/Partners";
import Revolution from "./Components/Revolution/Revolution";
import Features from "./Components/Features/Features";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";
import Robot from "./Components/Robot/robot";
import Login from "./Components/Login/login";
import SignUp from "./Components/SignUp/signUp";
import ForgetPassword from "./Components/ForgetPassword/forgetPassword";
import Dashboard from "./Components/Dashboard/dashboard";
import Rewards from "./Components/Rewards/Rewards";
import Overview from "./Components/AccountOverview/Overview";
import Vip from "./Components/Vip/Vip";
import Wallet from "./Components/Wallet/Wallet";
import Referal from "./Components/ReferalProgram/Referal";
import Setting from "./Components/AccountSetting/Setting";
import Drawer from "./Components/Drawer/Drawer";
import { Route, Router, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/forget" element={<ForgetPassword />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/Rewards" element={<Rewards />} />
          <Route exact path="Referal" element={<Referal />} />
          <Route exact path="Setting" element={<Setting />} />
          <Route exact path="Overview" element={<Overview />} />
          <Route exact path="Vip" element={<Vip />} />
          <Route exact path="Wallet" element={<Wallet />} />
          <Route exact path="Drawer" element={<Drawer />} />

          {/* <Route component={NotFound} /> */}
        </Routes>
      </BrowserRouter>
      {/* <Robot />
        <Login />
        <SignUp />
        <ForgetPassword /> */}
      {/* <Dashboard /> */}
    </>
  );
}

export default App;
