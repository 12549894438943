import Customizable from "../Components/Customizable/Customizable";
import Features from "../Components/Features/Features";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import Immersive from "../Components/Immersive/Immersive";
import Main1 from "../Components/Main1/Main1";
import Multiplayer from "../Components/Multiplayer/Multiplayer";
import Participate from "../Components/Participate/Participate";
import Partners from "../Components/Partners/Partners";
import Revolution from "../Components/Revolution/Revolution";
import Social from "../Components/Social/Social";
import Token from "../Components/Token/Token";
import Trade from "../Components/Trade/Trade";

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Main1 /> */}
      {/* <Immersive /> */}
      {/* <Participate />
      <Customizable />
      <Trade />
      <Social />
      <Multiplayer />
      <Token />
      <Partners />
      <Features />
      <Revolution />
      <Footer /> */}
    </>
  );
};
export default Home;
