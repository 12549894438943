import React, { useState, useEffect } from "react";
import "./Wallet.css";
import myImage from "../../Images/logomain.png";
import { Link, useNavigate } from "react-router-dom";
import profileImage from "../../Images/profile.png";
import vipImage from "../../Images/vip.png";
import profile from "../../Images/immersive.png";
import Navbar from "../Navbar/Navbar";
import Drawer from "../Drawer/Drawer";
import { Icon } from "@iconify/react";
import Chart from "react-apexcharts";
const Wallet = () => {
  const [activeButtons, setActiveButtons] = useState([]);
  const [showOptionsMobile, setShowOptionsMobile] = useState(true);

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate("/dashboard");
  };

  const showMenu = () => {
    setShowOptionsMobile(true);
  };
  const hideMenu = () => {
    setShowOptionsMobile(false);
  };
  const windowWidth = window.innerWidth;
  useEffect(() => {
    if (windowWidth > 650) {
      setShowOptionsMobile(true);
      console.log("greater than 650");
    } else {
      setShowOptionsMobile(false);
      console.log("smaller than 650");
    }
  }, [windowWidth]);

  return (
    <>
      <section className="Wallet">
        <div
          className="Wallet-view-Wallet"
          style={{
            transform: `translateX(${showOptionsMobile ? "0" : "-1000px"})`,
          }}
        >
          <Drawer />

          <button
            className="Wallet-view-two-one-button-Back"
            onClick={hideMenu}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color="white"
              width="25"
              height="25"
            />
            {/* <p className="buttonText">Back</p> */}
          </button>
        </div>

        {/* this is mainView */}
        <div className="Wallet-view-main">
          {/* header section view */}
          <div className="Wallet-view-main-first">
            <div
              className="drawerIcons d-flex justify-content-center"
              onClick={showMenu}
            >
              <Icon
                icon="mingcute:menu-fill"
                color="white"
                width="30"
                height="30"
              />
            </div>
            <div className="rightDiv d-flex justify-content-center">
              <button className="nav-btn-one m-0">Connect Wallet</button>
              <div className="nav-div">
                <div className="nav-div-div">
                  <img
                    src={profileImage}
                    alt="Profile Image"
                    height="35"
                    width="35"
                  />
                </div>
                <a
                  class="nav-div-link-tag"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SEEMI75
                </a>
                <Icon
                  icon="icon-park-outline:down"
                  color="white"
                  width="25"
                  height="25"
                />
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Name
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Edit Profile Picture
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Check Status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* main section view */}
          <div className="Wallet-view-main-second">
            <div className="heading">
              <p className="text">Wallet</p>
            </div>

            <div className="comingsoon">
              <p className="text">COMING SOON</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wallet;
