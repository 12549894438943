import React from "react";
// import "./Navbar.scss";
import "./Navbar.scss";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
function Navbar() {
  const navigate = useNavigate();
  return (
    <section className="Navbar">
      {/* <Link to="/login">lkogiuns</Link> */}
      <div className="Navbar-main">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <img className="img-fluid" src="/Images/logomain.png" alt="" />{" "}
            </a>
            <button
              class="navbar-toggler nav-menu"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <Icon
                class="navbar-toggler-icon"
                icon="material-symbols:menu-open-rounded"
                color="white"
                width="24"
                height="24"
              /> */}
            </button>
            {/*  
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">
                    marketplace
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    brand center
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    content creator
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    team
                  </a>
                </li>
                {/* <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Info
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        Edit Name
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Edit Profile Picture
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Check Status
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <button
                className="nav-btn"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </button>
            </div>
          */}
          </div>
        </nav>
      </div>
      <img src="/Images/navbg.png" alt="..." className="navback" />
    </section>
  );
}

export default Navbar;
