import React, { useState, useEffect } from "react";
import "./Drawer.css";
import myImage from "../../Images/logomain.png";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";

const Drawer = () => {
  const [showOptionsMobile, setShowOptionsMobile] = useState(true);
  const [showOptions, setShowOptions] = useState(true);

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate("/");
  };
  const handleToggleOptions = () => {
    console.log("caleedddd ---", showOptions);
    setShowOptions(!showOptions);
  };

  const showMenu = () => {
    setShowOptionsMobile(true);
  };
  const hideMenu = () => {
    setShowOptionsMobile(false);
  };
  const windowWidth = window.innerWidth;
  useEffect(() => {
    if (windowWidth > 650) {
      setShowOptionsMobile(true);
      console.log("greater than 650");
    } else {
      setShowOptionsMobile(false);
      console.log("smaller than 650");
    }
  }, [windowWidth]);

  return (
    <>
      <div className="drawer-view-logo">
        <img src={myImage} alt="My Image" onClick={handleImageClick} />
      </div>

      <Link to="/Overview" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon icon="mdi:home" color="white" width="25" height="25" />
          <p className="buttonText">Account Overview</p>
        </button>
      </Link>

      <Link to="/Rewards" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon
            icon="fluent:reward-12-filled"
            color="white"
            width="25"
            height="25"
          />
          <p className="buttonText">My Rewards</p>
        </button>
      </Link>

      <Link to="/Wallet" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon icon="solar:wallet-bold" color="white" width="25" height="25" />
          <p className="buttonText">Wallet</p>
        </button>
      </Link>

      <Link to="/Referal" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon
            icon="clarity:internet-of-things-line"
            color="white"
            width="25"
            height="25"
          />
          <p className="buttonText">Refferal Program</p>
        </button>
      </Link>

      <Link to="/Vip" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon icon="ri:vip-line" color="white" width="25" height="25" />
          <p className="buttonText">My Vip</p>
        </button>
      </Link>

      <Link to="/Setting" style={{ textDecoration: "none" }}>
        <button className="drawer-view-two-one-button">
          <Icon
            icon="ant-design:setting-filled"
            color="white"
            width="25"
            height="25"
          />
          <p className="buttonText">Account Setting</p>
        </button>
      </Link>
    </>
  );
};

export default Drawer;
