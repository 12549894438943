import React, { useState } from "react";
import "./signUp.css";
import myImage from "../../Images/logomain.png";
import Navbar from "../Navbar/Navbar";
import { Icon } from "@iconify/react";
const SignUp = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <section className="signUp">
        <Navbar />
        <div className="main-view">
          <div className="main-view-one">
            <img src={myImage} alt="My Image" />
          </div>

          {/* userName Password  Email */}
          <div className="main-view-two">
            <div className="inputDiv">
              <a href="#">
                <Icon icon="mdi:account" color="white" width="25" height="25" />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Username"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <div className="inputDiv">
              <a href="#">
                <Icon
                  icon="eva:email-fill"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Email"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <div className="inputDiv">
              <a href="#">
                <Icon
                  icon="ph:phone-fill"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Phone"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <div className="inputDiv">
              <a href="#">
                <Icon
                  icon="mdi:password"
                  color="white"
                  width="25"
                  height="25"
                />
              </a>
              <input
                className="inputSection"
                type="text"
                placeholder="Password"
                value={Email}
                onChange={handleChange}
              />
            </div>

            <button className="button">
              <p className="buttonText">SignUp</p>
            </button>

            <p className="textTwo">
              Have Account{" "}
              <a className="textTwoA" href="/Login">
                Go to Login
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
