import React from "react";
import "./Hero.scss";
import Navbar from "../Navbar/Navbar";
import heroVid from "../../Images/homefinal.mp4";
function Hero() {
  return (
    <section className="Hero">
      <Navbar />
      <div className="Hero-main">
        <h1>
          Welcome to the <br className="mob"></br> Metanexus World{" "}
        </h1>
        <p>
          Embrace the future and explore Metanexus, the{" "}
          <br className="mob"></br> groundbreaking ecosystem bridging virtual
          and <br className="mob"></br> real-world experiences!
        </p>
        <div className="hero-button">
          <button>COMING SOON</button>
        </div>
      </div>
      <video muted autoPlay loop playsInline className="back-video">
        <source src={heroVid} type="video/mp4" />
      </video>
      <img src="/Images/herobg.png" alt="..." className="hero-footer" />
    </section>
  );
}

export default Hero;
